import React from "react";
import { Section } from "../../../components/text/Section";

export const FAQs = () => (
  <div className="prose prose-slate max-w-none font-inter">
    <Section>
      <h2 className="text-3xl font-semibold text-slate-900 mb-4">
        Frequently Asked Questions
      </h2>
      <p className="text-lg text-slate-600 leading-relaxed">
        Find answers to common questions about using Explorer.
      </p>
    </Section>

    <Section title="General Questions">
      <div className="space-y-6">
        <div>
          <h4 className="font-medium text-slate-900 mb-2">
            How do I submit feedback?
          </h4>
          <p className="text-slate-600">
            Use the feedback button in the bottom right corner of the page.
          </p>
        </div>

        <div>
          <h4 className="font-medium text-slate-900 mb-2">
            Can I export data?
          </h4>
          <p className="text-slate-600">
            Yes, look for the export button in the top right corner of any data
            table. For visualizations, hover over the chart to view options to
            download the image as a PNG or copy the data to your clipboard.
          </p>
        </div>
      </div>
    </Section>

    <Section title="Data & Analysis" isLast={true}>
      <div className="space-y-6">
        <div>
          <h4 className="font-medium text-slate-900 mb-2">
            What data sources do you use?
          </h4>
          <p className="text-slate-600">
            We combine data from various official sources, including state voter
            files, census data, and election results.
          </p>
        </div>

        <div>
          <h4 className="font-medium text-slate-900 mb-2">
            How often is the data updated?
          </h4>
          <p className="text-slate-600">
            Data are updated monthly. Check the Release Notes for specific
            update information.
          </p>
        </div>

        <div>
          <h4 className="font-medium text-slate-900 mb-2">
            How do I interpret the net-Republican/Democratic votes chart on the
            'Turnout Scores' tab?
          </h4>
          <p className="text-slate-600">
            The chart shows the marginal number of votes each party would win or
            lose by. For example, if the chart shows a net-Democratic vote of
            100,000, that means that in the election type selected, a generic
            Democratic candidate would win by 100,000 votes.
          </p>
        </div>
      </div>
    </Section>
  </div>
);
