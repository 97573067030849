import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import fetchMapData from "../../utility/maps/fetchMapData";
import { addMapHover } from "../../utility/maps/addMapHover";
import { addMapLayer } from "../../utility/maps/addMapLayer";
import { formatTitle } from "../../utility/formatText";
import { newtonsCradle } from "ldrs";
import { ReusableCombobox } from "../forms/ReusableCombobox";
import { geographyMapping } from "../../pages/search/config";

newtonsCradle.register();

function ChoroplethMap() {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [activeLayer, setActiveLayer] = useState(null);
  const [activeMetric, setActiveMetric] = useState(null);
  const { results, loading, parameters } = useSelector((state) => state.search);
  const [isLoading, setIsLoading] = useState(false);

  // Get unique geography levels and set initial active layer
  useEffect(() => {
    if (parameters?.geographies?.length) {
      const firstLevel = parameters.geographies[0].geography_level;
      const initialLayer =
        firstLevel === "atlas_school_district" ||
        firstLevel === "Atlas school districts"
          ? parameters.geographies[1]?.geography_level
          : firstLevel;
      setActiveLayer(geographyMapping[initialLayer] || initialLayer);
    }
    if (parameters?.metrics?.length) {
      setActiveMetric(parameters.metrics[parameters.metrics.length - 1]);
    }
  }, [parameters]);

  // Get unique geography levels for layer switching
  const uniqueLayers = [
    ...new Set(
      parameters?.geographies
        ?.map((g) => geographyMapping[g.geography_level] || g.geography_level)
        .filter((level) => level !== "atlas_school_district") || []
    ),
  ];

  // Initialize map
  useEffect(() => {
    if (!map && mapContainerRef.current) {
      // Small delay to ensure container is properly sized
      const timer = setTimeout(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;
        const newMap = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: "mapbox://styles/mapbox/light-v10",
          center: [-98.5795, 38.4283],
          zoom: 3,
        });

        setMap(newMap);
      }, 100);

      return () => {
        clearTimeout(timer);
        if (map) {
          map.remove();
        }
      };
    }
  }, [map]);

  useEffect(() => {
    if (map && activeLayer && activeMetric) {
      const fetchDataAndAddSource = async () => {
        setIsLoading(true);
        try {
          // Clean up existing layers and source
          const cleanup = () => {
            // Remove layers first (they depend on the source)
            if (map.getLayer("polygons-fill")) {
              map.removeLayer("polygons-fill");
            }
            if (map.getLayer("polygons-outline")) {
              map.removeLayer("polygons-outline");
            }
            // Then remove the source
            if (map.getSource("polygons")) {
              map.removeSource("polygons");
            }
          };

          // Ensure map is loaded before cleanup
          if (map.loaded()) {
            cleanup();
          } else {
            await new Promise((resolve) => map.on("load", resolve));
            cleanup();
          }

          // Extract all geo_query values from results
          const geoQueries = results.map((result) => result.geo_query);

          // Pass geo_queries to fetchMapData
          const geojson = await fetchMapData(
            `/api/map/${activeLayer}`,
            geoQueries
          );

          // Merge search results data with geojson features
          geojson.features.forEach((feature) => {
            const result = results.find(
              (res) => res.geo_query === feature.properties.geo_query
            );
            if (result) {
              // Add all metrics to feature properties
              parameters.metrics.forEach((metric) => {
                feature.properties[metric] = result[metric];
              });
            }
          });

          // Filter features to only those with data
          const featuresWithData = geojson.features.filter(
            (feature) => feature.properties[activeMetric] != null
          );

          // Calculate bounds if there are features with data
          if (featuresWithData.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();

            featuresWithData.forEach((feature) => {
              if (feature.geometry.type === "Polygon") {
                feature.geometry.coordinates[0].forEach((coord) => {
                  bounds.extend(coord);
                });
              } else if (feature.geometry.type === "MultiPolygon") {
                feature.geometry.coordinates.forEach((polygon) => {
                  polygon[0].forEach((coord) => {
                    bounds.extend(coord);
                  });
                });
              }
            });

            // Check if bounds wrap around the globe (typically due to Alaska)
            const boundsWidth =
              bounds.getNorthEast().lng - bounds.getSouthWest().lng;
            if (boundsWidth > 180) {
              // If bounds are too wide, force US bounds
              const usBounds = [
                [-125.00165, 24.9493], // Southwest coordinates
                [-66.9326, 49.5904], // Northeast coordinates
              ];
              bounds.setNorthEast(usBounds[1]);
              bounds.setSouthWest(usBounds[0]);
            }

            // Fit map to bounds with padding
            map.fitBounds(bounds, {
              padding: 50,
              maxZoom: 12, // Prevent zooming in too far
              duration: 1000, // Animation duration in milliseconds
            });
          }

          // Add source and layers
          map.addSource("polygons", {
            type: "geojson",
            data: geojson,
          });

          addMapLayer(map, geojson, activeMetric);
          const cleanupHover = addMapHover(map, parameters.metrics);
          setIsLoading(false);

          // Return cleanup function
          return () => {
            cleanupHover();
            cleanup();
          };
        } catch (error) {
          console.error("Error updating map:", error);
          setIsLoading(false);
        }
      };

      fetchDataAndAddSource();
    }
  }, [map, activeLayer, activeMetric, results]);

  return (
    <div className="relative mt-6 mb-6 rounded-lg shadow-2xl w-full">
      {isLoading && (
        <div className="absolute inset-0 bg-style-bg/80 z-20 flex items-center justify-center">
          <l-newtons-cradle
            size="120"
            speed="1.4"
            color="black"
          ></l-newtons-cradle>
        </div>
      )}
      <div className="absolute top-2 right-2 z-20 flex flex-col gap-2">
        <div className="bg-white p-2 rounded shadow">
          <h3 className="text-sm font-semibold mb-2">Geography Level</h3>
          <ReusableCombobox
            fillColor="bg-morange-100"
            items={uniqueLayers}
            selected={activeLayer}
            onSelect={setActiveLayer}
            displayValue={(layer) => formatTitle(layer)}
            formatOption={(layer) => formatTitle(layer)}
            placeholder="Select geography level"
          />
        </div>
        <div className="bg-white p-2 rounded shadow">
          <h3 className="text-sm font-semibold mb-2">Metric</h3>
          <ReusableCombobox
            fillColor="bg-morange-100"
            items={parameters?.metrics || []}
            selected={activeMetric}
            onSelect={setActiveMetric}
            displayValue={(metric) => formatTitle(metric)}
            formatOption={(metric) => formatTitle(metric)}
            placeholder="Select metric"
          />
        </div>
      </div>
      <div
        ref={mapContainerRef}
        className="w-full"
        style={{ height: "650px" }}
      ></div>
    </div>
  );
}

export default ChoroplethMap;
