import React, { useState, useEffect } from "react";
import { CatalogSearchBar } from "./CatalogSearchBar";
import { tableParameters } from "../../pages/search/config";

const MetricsTab = ({ activeTab, onMetricSelect, isItemSelected }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedCategory, setExpandedCategory] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(tableParameters);

  useEffect(() => {
    // Reset expandedCategory when the Metrics tab is selected
    if (activeTab === "metrics") {
      setExpandedCategory(null);
    }
  }, [activeTab]);

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  return (
    <div>
      <CatalogSearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        data={tableParameters}
        paramType="metrics"
        onFilter={setFilteredCategories}
      />

      {expandedCategory === null ? (
        <div className="grid grid-cols-4 gap-3">
          {Object.keys(filteredCategories).map((category) => (
            <div key={category}>
              <button
                onClick={() => toggleCategory(category)}
                className="flex flex-col items-center justify-center w-full h-24 font-inter text-style-text-dark shadow-md text-sm rounded-lg bg-style-surface border-none hover:bg-style-bg"
              >
                <div className="flex items-center justify-center w-8 h-8 text-slate-600 rounded-full">
                  {tableParameters[category].icon}
                </div>
                <span className="mt-2">
                  {category
                    .replace(/_/g, " ")
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <button
            onClick={() => setExpandedCategory(null)}
            className="mb-4 px-4 py-2 font-inter text-style-text-dark shadow-md text-sm rounded-lg bg-style-surface border-none hover:bg-style-bg"
          >
            Back to Metric Categories
          </button>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {filteredCategories[expandedCategory] &&
              filteredCategories[expandedCategory].map((metric) => (
                <div key={metric} className="flex items-center">
                  <button
                    onClick={() => onMetricSelect(metric)}
                    className={`flex flex-col items-center justify-center w-full h-24 font-inter text-style-text-dark shadow-md text-sm rounded-lg border-none hover:bg-style-bg relative
                      ${isItemSelected(metric, "metrics") ? "bg-style-bg" : "bg-style-surface"}`}
                  >
                    {isItemSelected(metric, "metrics") && (
                      <div className="absolute top-2 right-2">
                        <svg
                          className="w-5 h-5 text-green-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                    )}
                    {metric
                      .replace(/_/g, " ")
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </button>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { MetricsTab };
