import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import { GettingStarted } from "./content/GettingStarted";
import { ReleaseNotes } from "./content/ReleaseNotes";
import { FAQs } from "./content/FAQs";

const sections = {
  "getting-started": {
    title: "Getting Started",
    content: <GettingStarted />,
  },
  "release-notes": {
    title: "Release Notes",
    content: <ReleaseNotes />,
  },
  faqs: {
    title: "FAQs",
    content: <FAQs />,
  },
};

export const HelpCenter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = location.hash.replace("#", "") || "getting-started";

  // Add spring animation for content fade
  const fadeProps = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: { opacity: 1, transform: "translate3d(0,0,0)" },
    reset: true,
    key: currentTab, // Reset animation when tab changes
  });

  const handleTabChange = (tabId) => {
    navigate(`#${tabId}`);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header Section */}
      <div className="border-b border-slate-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <img
            src="/Explorer_2024_Logo_WithLargeTag_Amber.png"
            alt="Explorer Logo"
            className="h-16 w-auto mb-4"
          />
          <h1 className="text-3xl font-inter font-semibold text-center">
            Help Center
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex gap-12">
          {/* Sidebar Navigation */}
          <div className="w-64 flex-shrink-0">
            <div className="sticky top-8">
              <nav className="space-y-1 border-r border-slate-200 pr-8 relative">
                {Object.entries(sections).map(([id, section]) => (
                  <button
                    key={id}
                    onClick={() => handleTabChange(id)}
                    className={`
                      w-full text-left px-4 py-2.5 rounded-md text-sm font-medium transition-colors
                      ${
                        currentTab === id
                          ? "bg-morange-50 text-morange-700 border-l-2 border-morange-500"
                          : "text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                      }
                    `}
                  >
                    {section.title}
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {/* Animated Main Content */}
          <animated.div style={fadeProps} className="flex-1 max-w-3xl">
            {sections[currentTab].content}
          </animated.div>
        </div>
      </div>
    </div>
  );
};
