import React from "react";
import { tableParameters } from "../../pages/search/config";

const titleCase = (str) =>
  str
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const TableParameters = ({ parameters }) => {
  const renderFilters = (filters) => {
    if (!filters || filters.length === 0) {
      return (
        <span className="text-morange-700 decoration-black">all voters</span>
      );
    }

    const filterGroups = {};
    filters.forEach((filter) => {
      let immediateParent = null;

      for (const [categoryKey, category] of Object.entries(tableParameters)) {
        if (category && typeof category === "object" && "filters" in category) {
          if (Array.isArray(category.filters)) {
            // Check if filter is in the array
            const matchingFilter = category.filters.find(
              (f) =>
                f.toLowerCase().replace(/\s/g, "_") === filter.toLowerCase()
            );
            if (matchingFilter) {
              immediateParent = categoryKey;
              break;
            }
          } else if (typeof category.filters === "object") {
            // Check nested filters object
            for (const [subKey, subValue] of Object.entries(category.filters)) {
              if (
                filter
                  .toLowerCase()
                  .startsWith(subKey.toLowerCase().replace(/\s/g, "_"))
              ) {
                immediateParent = subKey;
                break;
              }
            }
            if (immediateParent) break;
          }
        }
      }

      if (immediateParent) {
        if (!filterGroups[immediateParent]) {
          filterGroups[immediateParent] = [];
        }
        filterGroups[immediateParent].push(filter);
      }
    });

    return Object.entries(filterGroups).map(
      ([filterType, groupFilters], groupIndex) => (
        <React.Fragment key={filterType}>
          {groupIndex > 0 && " AND "}
          {groupFilters.length > 1 && "("}
          {groupFilters.map((filter, index) => (
            <React.Fragment key={filter}>
              {index > 0 && " OR "}
              <span className="text-morange-700 group inline-flex items-center">
                <span className="decoration-black">
                  {titleCase(filter.split("_").join(" "))}
                </span>
              </span>
            </React.Fragment>
          ))}
          {groupFilters.length > 1 && ")"}
        </React.Fragment>
      )
    );
  };

  const renderParameter = (value, type) => {
    if (type === "filters") {
      return renderFilters(value);
    } else if (type === "places") {
      const placeText =
        parameters.search === "simple"
          ? `places like "${value}"`
          : value.join(" vs ");
      return (
        <span className="text-morange-700">
          <span className="decoration-black">{placeText}</span>
        </span>
      );
    } else if (!value || (Array.isArray(value) && value.length === 0)) {
      return (
        <span className="text-morange-700 decoration-black">
          {type === "metrics" ? "Registered Voters" : `Select ${type}`}
        </span>
      );
    }

    const renderItem = (item) => (
      <span className="text-morange-700 group inline-flex items-center">
        <span className="decoration-black">
          {/* underline underline-offset-2  */}
          {titleCase(item)}
        </span>
      </span>
    );

    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <React.Fragment key={item}>
          {renderItem(item)}
          {index < value.length - 1 && ", "}
        </React.Fragment>
      ));
    }

    return renderItem(value);
  };

  return (
    <div className="w-full mt-8 flex flex-col container mx-auto items-start space-y-4">
      <div className="text-lg max-w-prose tracking-tight font-light">
        Show me {renderParameter(parameters.metrics, "metrics")} for{" "}
        {renderParameter(parameters.filters, "filters")} in{" "}
        {renderParameter(parameters.places, "places")}{" "}
      </div>
    </div>
  );
};

export default TableParameters;
