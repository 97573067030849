import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchResults,
  resetParameters,
  clearError,
} from "../../store/searchPage";
import { Table } from "../../components/tables/Table";
import TableParameters from "../../components/tables/TableParameters";
import SearchBar from "../../components/search/SearchBar";
import { newtonsCradle } from "ldrs";
import ChoroplethMap from "../../components/maps/ChoroplethMap";
import { TableCellsIcon, MapIcon } from "@heroicons/react/20/solid";

newtonsCradle.register();

const SearchResults = ({ queryString = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = queryString || searchParams.get("query");
  const isFromDataCatalog = searchParams.get("fromDataCatalog");

  // Get initial view from URL or default to "table"
  const initialView = searchParams.get("view") || "table";
  const [view, setView] = useState(initialView);

  const { results, loading, parameters, error } = useSelector(
    (state) => state.search
  );

  // Update URL when view changes
  const handleViewChange = (newView) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("view", newView);
    // Update URL without reloading the page
    navigate(`${location.pathname}?${newSearchParams.toString()}`, {
      replace: true,
    });
    setView(newView);
  };

  useEffect(() => {
    if (query && !isFromDataCatalog) {
      dispatch(fetchResults(query));
    }

    return () => {
      dispatch(clearError()); // Clear error when component unmounts
    };
  }, [query, dispatch, isFromDataCatalog]);

  useEffect(() => {
    if (error) {
      dispatch(resetParameters());
      navigate("/error", {
        state: {
          message: error,
        },
      });
    }
  }, [error, navigate, dispatch]);

  const hasValidMapLayers = React.useMemo(() => {
    if (!parameters?.geographies?.length) return false;

    // If atlas_school_district is the only geography level, don't show map option
    if (
      parameters.geographies.length === 1 &&
      ["atlas_school_district", "Atlas school districts"].includes(
        parameters.geographies[0].geography_level
      )
    ) {
      return false;
    }

    return true;
  }, [parameters?.geographies]);

  console.log("has Valid Map Layers", hasValidMapLayers);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-style-bg">
        <l-newtons-cradle
          size="120"
          speed="1.4"
          color="black"
        ></l-newtons-cradle>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-style-bg">
      <div className="w-[600px] h-[64px] max-w-xl mt-8">
        <SearchBar useCarousel={false}></SearchBar>
      </div>
      {results.length > 0 ? (
        <>
          <div className={`${view === "map" ? "w-[90vw]" : "min-w-[64rem]"}`}>
            <div className="flex justify-between items-center">
              {parameters && <TableParameters parameters={parameters} />}
              {parameters?.search !== "simple" && hasValidMapLayers && (
                <div className="flex justify-end items-center">
                  <div className="flex items-center gap-2 px-4 py-2 rounded-lg">
                    <span className="font-inter font-light tracking-tight text-lg">
                      View:
                    </span>
                    <div className="flex gap-1 bg-style-surface rounded-md p-1">
                      <button
                        onClick={() => handleViewChange("table")}
                        className={`p-1.5 rounded-md transition-colors duration-200 ${
                          view === "table"
                            ? "bg-white text-morange-700 shadow-lg"
                            : "text-slate-600 hover:text-slate-900"
                        }`}
                        title="Table view"
                      >
                        <TableCellsIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleViewChange("map")}
                        className={`p-1.5 rounded-md transition-colors duration-200 ${
                          view === "map"
                            ? "bg-white text-morange-700 shadow-lg"
                            : "text-slate-600 hover:text-slate-900"
                        }`}
                        title="Map view"
                      >
                        <MapIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {parameters?.search === "simple" ||
            view === "table" ||
            !hasValidMapLayers ? (
              <Table
                data={results}
                showExportButton={true}
                columnConfig={[
                  {
                    key: "name",
                    type: "link",
                    hrefSource: "geo_query",
                    hrefModifier: (geo_query) =>
                      `/district-profile?${geo_query}`,
                  },
                ]}
              />
            ) : (
              <ChoroplethMap />
            )}
          </div>
        </>
      ) : (
        <p className="text-gray-500 ">No results found for "{query}"</p>
      )}
    </div>
  );
};

export default SearchResults;
