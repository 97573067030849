import { useState, useRef, useEffect } from "react";
import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

const HelpBubble = ({ defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const panelRef = useRef(null);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Help panel */}
      {isOpen && (
        <div
          ref={panelRef}
          className="absolute bottom-16 right-0 w-64 bg-white rounded-lg shadow-lg p-4 mb-2"
        >
          {/* Close button */}
          <button
            onClick={() => setIsOpen(false)}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>

          {/* Panel title */}
          <h3 className="text-lg font-semibold text-slate-700 mb-4 pr-6">
            Help & Feedback
          </h3>

          {/* Links */}
          <div className="space-y-3">
            <a
              href="/help-center"
              target="_blank"
              rel="noopener noreferrer"
              className="block p-2 hover:bg-gray-100 rounded-md text-slate-700 transition-colors"
            >
              📋 Help Center
            </a>
            <a
              href="https://forms.gle/MvtNKaY1oRT7omAv5"
              target="_blank"
              rel="noopener noreferrer"
              className="block p-2 hover:bg-gray-100 rounded-md text-slate-700 transition-colors"
            >
              📝 Provide Feedback
            </a>
          </div>
        </div>
      )}

      {/* Floating bubble */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-morange-600 hover:bg-morange-700 text-white rounded-full p-3 shadow-lg transition-colors"
      >
        <QuestionMarkCircleIcon className="w-6 h-6" />
      </button>
    </div>
  );
};

export { HelpBubble };
