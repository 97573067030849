// Routes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import SecureRoute from "./components/auth/SecureRoute";
import oktaAuth from "./auth/oktaAuth";
import { LandingPage } from "./pages/search/LandingPage";
import Login from "./pages/auth/Login";
import Logout from "./components/auth/Logout";
import SearchResults from "./pages/search/SearchResults";
import ScrollableLayout from "./pages/district/ScrollableLayout";
import { HealthPage } from "./pages/HealthPage";
import { ErrorPage } from "./pages/ErrorPage";
import { EarlyVotePage } from "./pages/earlyVote/EarlyVotePage";
import { HelpCenter } from "./pages/helpCenter/HelpCenter";

function AppRoutes() {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(originalUri || window.location.origin);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/health" element={<HealthPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/"
          element={
            <SecureRoute>
              <LandingPage />
            </SecureRoute>
          }
        />
        <Route
          path="/error"
          element={
            <SecureRoute>
              <ErrorPage />
            </SecureRoute>
          }
        />
        <Route
          path="/search-results"
          element={
            <SecureRoute>
              <SearchResults />
            </SecureRoute>
          }
        />
        <Route
          path="/district-profile"
          element={
            <SecureRoute>
              <ScrollableLayout />
            </SecureRoute>
          }
        />
        <Route
          path="/early-voting"
          element={
            <SecureRoute>
              <EarlyVotePage />
            </SecureRoute>
          }
        />
        <Route
          path="/help-center"
          element={
            <SecureRoute>
              <HelpCenter />
            </SecureRoute>
          }
        />
      </Routes>
    </Security>
  );
}

export default AppRoutes;
