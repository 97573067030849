import React, { useState, useEffect, useCallback, useRef } from "react";
import debounce from "lodash.debounce";
import apiClient from "../../utility/axios";
import { dotStream } from "ldrs";

dotStream.register();

const FuzzyGeoSearch = ({
  placeholder = "Search for a place...",
  handleSelect,
  restrictLayers = true,
}) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef(null);

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.length > 1) {
        setLoading(true);
        try {
          const response = await apiClient.get("/api/geodb", {
            params: {
              query,
            },
          });
          setSuggestions(response.data);
          setShowDropdown(true);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setShowDropdown(false);
      }
    }, 1000),
    [setSuggestions, setShowDropdown]
  );

  useEffect(() => {
    if (shouldFetch) {
      fetchSuggestions(query);
    } else {
      setShouldFetch(true);
    }
  }, [query, fetchSuggestions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form
      ref={wrapperRef}
      className="relative w-full max-w-xl h-full h-12"
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full h-full min-h-[3rem] font-inter font-lighter text-sm px-10 py-auto border border-gray-100 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-morange-200"
      />
      {loading && (
        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
          <l-dot-stream size="40" speed="2.5" color="black"></l-dot-stream>
        </div>
      )}
      {showDropdown && (
        <ul className="absolute left-0 right-0 z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
          {suggestions.length > 0 ? (
            suggestions.map((item) => (
              <li
                key={item.name}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  setShouldFetch(false);
                  handleSelect(item);
                  setQuery(item.name);
                  setShowDropdown(false);
                }}
              >
                {item.name}
              </li>
            ))
          ) : (
            <li className="px-4 py-2 text-gray-500">No results found</li>
          )}
        </ul>
      )}
    </form>
  );
};

export default FuzzyGeoSearch;
