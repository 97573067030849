import React from "react";

export const Section = ({ title, children, isLast = false }) => (
  <div className={`mb-12 ${!isLast ? "pb-12 border-b border-slate-200" : ""}`}>
    {title && (
      <h2 className="text-2xl font-semibold text-slate-900 mb-6">{title}</h2>
    )}
    <div className="text-lg text-slate-600">{children}</div>
  </div>
);
